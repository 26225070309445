import {
  COMPANY_PROFILE,
  DASHBOARD,
  INVOICE_LIST,
  ROLES,
  ROLE_FEATURE,
  USERS,
  USER_PROFILE,
} from "@constants/internal-route.constant";
import logoImg from "../assets/image/logo.png";

import { CHANGE_PASSWORD, LOGIN } from "@constants/internal-route.constant";
import { useAuth } from "@context/Auth";
import { ENV } from "config/ENV.config";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, currentUser } = useAuth();

  const productCode = ENV.productCode || "";

  const handleLogout = () => {
    logout();
    localStorage.clear();
    setTimeout(() => {
      navigate(LOGIN);
    }, 100);
  };

  return (
    <Navbar expand="lg" className="header-wrap">
      <Container className="d-flex align-items-center justify-content-between">
        {productCode == "HRBOI" ? (
          <Link to="https://hrboi.com">
            <Navbar.Brand>
              <img src={logoImg} alt="logo" style={{ height: "45px" }} />
            </Navbar.Brand>
          </Link>
        ) : (
          <Link to="https://erpboi.com">
            <Navbar.Brand>
              <img src={logoImg} alt="logo" style={{ height: "45px" }} />
            </Navbar.Brand>
          </Link>
        )}

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto justify-content-end w-100">
            <Nav.Link
              as={Link}
              to="https://hrboi.com/pricing/"
              active={location.pathname == "/pricing"}
            >
              Pricing
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="https://hrboi.com/about-us/"
              active={location.pathname == "/about-us"}
            >
              About us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="https://hrboi.com/contact-us/"
              active={location.pathname == "/contact-us"}
            >
              Contact us
            </Nav.Link>

            <div className="d-flex ms-auto">
              {currentUser ? (
                <>
                  {productCode == "HRBOI" ? (
                    <Nav.Link
                      className="ms-2 me-4 border border-primary-subtle px-2"
                      target="_blank"
                      as={Link}
                      to="https://portal.hrboi.com/"
                    >
                      <b>Go to Portal</b>
                    </Nav.Link>
                  ) : (
                    <></>
                  )}

                  <NavDropdown
                    title="ACL"
                    className="ms-2 px-2"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item as={Link} to={USERS}>
                      Users
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={ROLES}>
                      Roles
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={ROLE_FEATURE}>
                      Role Feature
                    </NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown
                    title="Profiles"
                    className="ms-2 border border-primary-subtle rounded-pill px-2"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item as={Link} to={DASHBOARD}>
                      Dashboard
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={INVOICE_LIST}>
                      Invoices
                    </NavDropdown.Item>
                    <NavDropdown.Divider></NavDropdown.Divider>
                    <NavDropdown.Item as={Link} to={USER_PROFILE}>
                      User Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={COMPANY_PROFILE}>
                      Company Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item as={Link} to={CHANGE_PASSWORD}>
                      Change Password
                    </NavDropdown.Item>
                    <NavDropdown.Divider></NavDropdown.Divider>
                    <NavDropdown.Item onClick={handleLogout}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Button
                  id="fade-button"
                  className="btn-sm rounded-pill px-3"
                  onClick={() => navigate(LOGIN)}
                >
                  <b>7 Days Trial</b>
                </Button>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
