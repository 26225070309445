import Button from "@components/Button";
import { Input } from "@components/Input";
import { Select } from "@components/Select";
import { COMPANY_ID } from "@constants/auth.constant";
import {
  FORGETPASSWORD,
  HOME,
  SIGNUP,
} from "@constants/internal-route.constant";
import { useAuth } from "@context/Auth";
import { AuthService } from "@services/api/Auth.service";
import { appLoginHelper } from "@services/helper/app.helper";
import { LocalStorageService } from "@services/utils/localStorage.service";
import { ToastService } from "@services/utils/toast.service";
import { ENV } from "config/ENV.config";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { saveAuth } = useAuth();
  const { state } = useLocation();
  const [username, setUsername] = useState<any>("");
  const [showVerificationBtn, setShowVerificationBtn] =
    useState<boolean>(false);
  const [companyDetails, setcompanyDetails] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (fomrData) => {
    const productCode = ENV.productCode;
    setIsLoading(true);
    if (!!companyDetails?.[0]?.companyId) {
      fomrData["companyId"] = companyDetails?.[0]?.companyId;
      fomrData["appName"] = productCode || "";
      AuthService.login(fomrData)
        .then((resp) => {
          setShowVerificationBtn(false);
          ToastService.success(resp?.msg);
          saveAuth(resp?.body?.initialToken, { ...resp?.body });
          state?.isAppLogin && appLoginHelper();
          !state?.isAppLogin && state?.from
            ? navigate(state.from)
            : navigate(HOME);
        })
        .catch((err) => {
          if (err?.code == 501) {
            // account is not verified
            setShowVerificationBtn(true);
          } else {
            setShowVerificationBtn(false);
          }
          ToastService.error(err?.msg);
        })
        .finally(() => setIsLoading(false));
    } else {
      ToastService.error("Please, check your Email/Username again.");
    }
  };

  const sendVerificationLink = () => {
    const productCode = ENV.productCode;
    let data = {};
    data["username"] = username;
    data["companyId"] = companyDetails?.[0]?.companyId;
    data["appName"] = productCode || "";
    AuthService.sendVerificationLink(data)
      .then((resp) => {
        setShowVerificationBtn(false);
        ToastService.success(resp?.msg);
      })
      .catch((err) => {
        ToastService.error(err?.msg);
      })
      .finally(() => setIsLoading(false));
  };

  const onBlurUsername = (value: string) => {
    if (value && value.length > 1) {
      setUsername(value);
      AuthService.getCompanyList({
        username: value,
      })
        .then((response) => {
          setcompanyDetails(response?.body);
          LocalStorageService.set(
            COMPANY_ID,
            response?.body?.[0]?.companyId + ""
          );
        })
        .catch((err) => ToastService.error(err?.msg))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {/* <Avatar sx={{ bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar> */}
      <h4 className="form-title"> Sign In to Your Account</h4>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Input
          label="Email/Username"
          registerProperty={{
            ...register("username", { required: "Please provide username" }),
          }}
          isError={!!errors?.username}
          errorMessage={errors?.username?.message as string}
          autoFocus={true}
          onBlur={(e) => onBlurUsername(e.target.value)}
        />
        {companyDetails?.length != 0 && (
          <Select
            label={"Company Name"}
            options={companyDetails}
            valueKey="companyId"
            textKey="companyName"
            acceptNull={false}
          />
        )}
        <Input
          label="Password"
          type="password"
          registerProperty={{
            ...register("password", { required: "Please enter your password" }),
          }}
          isError={!!errors?.password}
          errorMessage={errors?.password?.message as string}
        />

        {showVerificationBtn ? (
          <div>
            <Button
              className="w-100"
              color="success"
              type="button"
              size="lg"
              onClick={() => sendVerificationLink()}
            >
              Send Verification Link
            </Button>
          </div>
        ) : (
          <></>
        )}

        <div>
          <Button
            className="w-100 mt-4"
            color="primary"
            type="submit"
            size="lg"
            isLoading={isLoading}
            loadingText="Saving..."
            isDisabled={companyDetails?.length == 0}
          >
            Login
          </Button>
        </div>

        <div className="new-user">
          <h5 className="mb-3">
            New User? <Link to={SIGNUP}>Sign Up Now</Link>
          </h5>
          <h5>
            <Link to={FORGETPASSWORD}>Forget Password?</Link>
          </h5>
        </div>
      </form>
    </>
  );
};

export default Login;
