export const HOME = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const APP_LOGIN = "/app-login";
export const FORGETPASSWORD = "/forget-password";
export const VERIFY_OTP = "/verify-otp";
export const RESET_PASSWORD = "/reset-password";
export const CHANGE_PASSWORD = "/change-password";
export const EDIT_PROFILE = "/edit-profile";
export const USER_PROFILE = "/profile";
export const COMPANY_PROFILE = "/company-profile";
export const PRICING = "/pricing";
export const DASHBOARD = "/dashboard";
export const USERS = "/users";
export const ROLES = "/roles";
export const ROLE_FEATURE = "/role-feature";
export const INVOICE_LIST = "/invoice-list";
export const INVOICE_DETAILS = "/invoice/:id";
export const PRODUCT_DETAILS = "/product/:id";
export const PAYMENT_STATUS = "/payment-resp";
