import { USER_INFO } from "@constants/auth.constant";
import { IAppRoutes } from "@interface/common.interface";
import UserLayout from "@layout/UserLayout";
import { setWindowTitle } from "@services/helper/common.helper";
import { LocalStorageService } from "@services/utils/localStorage.service";
import { TopProgressCom } from "@services/utils/topProgress.service";
import { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AppRouteList } from "./site-msp/app.routes";

const features = LocalStorageService.get(USER_INFO)?.features;

const routeList = (routes: IAppRoutes[]) => {
  return routes.map((route: IAppRoutes, index: number) => {
    const isUnauthorized =
      route?.routeCode && !features?.some((f) => f.code === route.routeCode);

    if (route.childrens && route.childrens.length) {
      return (
        <Route
          path={route.link}
          element={
            route.element && !isUnauthorized ? (
              <Suspense fallback={<TopProgressCom />}>
                <route.element />
              </Suspense>
            ) : isUnauthorized ? (
              <h1 className="text-center">Permission denied!</h1>
            ) : (
              <>
                <Outlet />
              </>
            )
          }
          key={index}
        >
          {routeList(route.childrens)}
        </Route>
      );
    }

    return (
      <Route
        path={route.link}
        element={
          route.redirect ? (
            <Navigate to={route.redirect} replace />
          ) : isUnauthorized ? (
            <h1 className="text-center">Permission denied!</h1>
          ) : (
            <Suspense fallback={<TopProgressCom />}>
              <route.element />
            </Suspense>
          )
        }
        key={index}
      />
    );
  });
};

const PrivateRoutes = () => {
  setWindowTitle();
  return (
    <Routes>
      <Route element={<UserLayout />}>
        {routeList(AppRouteList)}
        <Route
          path="*"
          element={<h1 className="text-center">Page not found</h1>}
        />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
