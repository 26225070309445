import {
  CHANGE_PASSWORD,
  COMPANY_PROFILE,
  DASHBOARD,
  EDIT_PROFILE,
  INVOICE_DETAILS,
  INVOICE_LIST,
  PAYMENT_STATUS,
  PRODUCT_DETAILS,
  ROLES,
  ROLE_FEATURE,
  USERS,
  USER_PROFILE,
} from "@constants/internal-route.constant";
import { ROUTE_CODES } from "@constants/route-codes.constant";
import { IAppRoutes } from "@interface/common.interface";
import { lazy } from "react";

export const AppRouteList: IAppRoutes[] = [
  {
    link: USERS,
    element: lazy(() => import("@modules/acl/User")),
    routeCode: ROUTE_CODES.USER,
  },
  {
    link: ROLES,
    element: lazy(() => import("@modules/acl/Role")),
    routeCode: ROUTE_CODES.ROLES,
  },
  {
    link: ROLE_FEATURE,
    element: lazy(() => import("@modules/acl/RoleFeature")),
    routeCode: ROUTE_CODES.ROLE_FEATURE_MAP,
  },
  {
    link: DASHBOARD,
    element: lazy(() => import("pages/Dashboard")),
  },
  {
    link: COMPANY_PROFILE,
    element: lazy(() => import("pages/CompanyProfile")),
    routeCode: ROUTE_CODES.COMPANY_PROFILE,
  },
  {
    link: CHANGE_PASSWORD,
    element: lazy(() => import("pages/ChangePassword")),
  },
  {
    link: USER_PROFILE,
    element: lazy(() => import("pages/Profile")),
  },
  {
    link: EDIT_PROFILE,
    element: lazy(() => import("pages/EditProfile")),
  },
  {
    link: PRODUCT_DETAILS,
    element: lazy(() => import("pages/ProductDetails")),
  },
  {
    link: PAYMENT_STATUS,
    element: lazy(
      () => import("@modules/billing/invoiceList/components/PaymentStatus")
    ),
  },
  {
    link: INVOICE_LIST,
    element: lazy(() => import("@modules/billing/invoiceList")),
    routeCode: ROUTE_CODES.INVOICE_LIST,
  },
  {
    link: INVOICE_DETAILS,
    element: lazy(() => import("@modules/billing/invoice")),
    routeCode: ROUTE_CODES.INVOICE_LIST,
  },
];
